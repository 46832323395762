import React from 'react';

import { Link } from 'gatsby-theme-material-ui';
import ThemeSwitch from './ThemeSwitch';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    header: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundColor: theme.palette.background.paper,
        padding: 8
    },
    link: {
        marginRight: 8
    }
}));

function Header({darkTheme, toggleDarkTheme}){
    const classes = useStyles();

    return (
        <header className={classes.header}>
            {/* <nav>
                <Link className={classes.link}to="/">Home</Link>
                <Link className={classes.link} to="/blog">Blog</Link>
            </nav>   */}
            <ThemeSwitch
                darkTheme={darkTheme}
                toggleDarkTheme={toggleDarkTheme}/>
        </header>
    );
}

export default Header;