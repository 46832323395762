import React, { useState } from "react"
import PropTypes from "prop-types"

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";

import themes from '../styles/themes'; 
import Header from './Header';

const Layout = (props) => {
	const { children } = props;

	let savedThemeName = typeof window !== `undefined` && window.localStorage.getItem("theme");
			
	const [themeName, setThemeName] = useState(savedThemeName ? savedThemeName : "light");
	const [theme, setTheme] = useState(themeName === "light" ? themes.light : themes.dark)

	const toggleTheme = (checked) => {
		const themeName = checked ? "dark" : "light";
		setThemeName(themeName);
		setTheme(themeName === "light" ? themes.light : themes.dark);
		if (typeof window !== `undefined`)
			window.localStorage.setItem("theme", themeName);
	}
	
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Container
				style={{
					backgroundColor: theme.palette.background.paper,
					minHeight: 640 }}
				maxWidth="md">
				<Header
					darkTheme={themeName === "dark" ? true : false}
					toggleDarkTheme={d => toggleTheme(d)} />
				<main>
					{children}
				</main>
				<footer style={{height: 48}}>
				</footer>
			</Container>
		</ThemeProvider>
	);
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
