import React from 'react';
import Switch from '@material-ui/core/Switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun as fasSun, faMoon as fasMoon } from '@fortawesome/free-solid-svg-icons';
import { faSun as farSun, faMoon as farMoon } from '@fortawesome/free-regular-svg-icons'

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: -4
    }
}));

function ThemeSwitch({darkTheme, toggleDarkTheme}){
    const classes = useStyles();

    return (
        <div style={{marginTop: 4}}>
            <FontAwesomeIcon size="lg" icon={darkTheme ? farSun : fasSun} />
            <Switch
                color="default"
                className={classes.root}
                checked={darkTheme}
                onChange={(e) => toggleDarkTheme(e.target.checked)} />
            <FontAwesomeIcon size="lg" icon={darkTheme ? fasMoon : farMoon} />
        </div>
    );
}

export default ThemeSwitch;