import { createMuiTheme } from "@material-ui/core";
const themes = {
		light: createMuiTheme({
		palette: {
			type: "light",
			primary: {
				main: "#D81B60"
			}
		},
		typography: {
			h1: {
				fontWeight: 400
			}
		}
	}),
	dark: createMuiTheme({
		palette: {
			type: "dark",
			primary: {
				main: "#D81B60"
			}
		},
		typography: {
			h1: {
				fontWeight: 400
			}
		}
	}),
}

export default themes;